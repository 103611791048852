@use 'base';
@use 'reset';

:root {
  /* Colors: */
  --done-color-00ff89: #00ff89;
  --done-color-333834: #333834;
  --done-color-ffffff: #ffffff;
  --done-color-000000: #000000;
  --done-color-eaeaf6: #eaeaf6;
  --done-color-disable: #ccc;
  --done-color-danger: #cc4d36;

  /* Font/text values */
  --done-font-family-signal-mono: Signal Mono;
  --done-font-family-pp-neue-montreal: PP Neue Montreal;
  --done-font-style-normal: normal;
  --done-font-weight-normal: normal;
  --done-font-weight-medium: 600;
  --done-font-weight-bold: bold;
  --done-font-size-12: 1.2rem;
  --done-font-size-14: 1.4rem;
  --done-font-size-16: 1.6rem;
  --done-font-size-18: 1.8rem;
  --done-font-size-20: clamp(1.8rem, 2vw, 2rem);
  --done-font-size-22: clamp(2rem, 2.2vw, 2.2rem);
  --done-font-size-28: clamp(2.4rem, 2.4vw, 2.8rem);
  --done-font-size-32: clamp(2.4rem, 2.4vw, 3.2rem);
  --done-font-size-50: clamp(3.6rem, 3vw, 5rem);
  --done-font-size-60: clamp(5rem, 3vw, 6rem);
  --done-font-size-80: clamp(5.5rem, 4.5vw, 8rem);
  --done-font-size-86: clamp(6rem, 4.5vw, 8.6rem);
  --done-font-size-100: clamp(6rem, 6vw, 10rem);
  --done-font-size-140: clamp(6.2rem, 8vw, 14rem);
  --done-character-spacing--0-2: -0.02rem;
  --done-character-spacing--0-22: -0.022rem;
  --done-character-spacing--0-32: -0.032rem;
  --done-character-spacing--0-5: -0.05rem;
  --done-character-spacing--1-4: -0.14rem;
  --done-character-spacing--0-16: -0.016rem;
  --done-character-spacing--0-14: -0.014rem;
  --done-line-spacing-20: 2rem;
  --done-line-spacing-25: 2.5rem;
  --done-line-spacing-28: clamp(2.4rem, 2.4vw, 2.8rem);
  --done-line-spacing-30: clamp(2.4rem, 2.4vw, 3.2rem);
  --done-line-spacing-55: clamp(4rem, 3vw, 5.5rem);
  --done-line-spacing-80: clamp(5.5rem, 4.5vw, 8rem);
  --done-line-spacing-100: clamp(6rem, 6vw, 10rem);
  --done-line-spacing-130: clamp(6.2rem, 8vw, 14rem);
  --done-text-transform-uppercase: uppercase;

  --done-large-devices: #{base.$large-devices};
}

html {
  font-size: 50%;
}

b {
  font-weight: var(--done-font-weight-bold);
}

@media only screen and (min-width: base.$x-small-devices) {
  html {
    font-size: 62.5%;
  }
}

/* Init CSS */

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

a,
button {
  all: unset;
  cursor: pointer;
}

a:hover,
a:focus,
a:active {
  text-decoration: underline;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

i {
  display: block;
  font-style: italic;
}

body {
  background: var(--done-color-eaeaf6);
}

table {
  border-collapse: collapse;

  th,
  td {
    padding: 2rem;
    border: 1px solid var(--done-color-333834);
    text-align: center;
  }
}

/* Utils */

.selectable {
  border-bottom: 0.6rem solid transparent;

  &:hover,
  &:active,
  &:focus {
    border-bottom: 0.6rem solid var(--done-color-00ff89);
  }
}

/* CSS Début */

.container {
  max-width: 192rem;
  margin: auto;
  position: relative;
}

.accent-color {
  color: var(--done-color-00ff89);
}

.no-break {
  white-space: nowrap;
}

.italic {
  font-style: italic;
}

.hidden {
  display: none;
}

.btn {
  height: 6.6rem;
  padding: 0 1.6rem;
  cursor: pointer;
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
    var(--done-font-size-20) / var(--done-line-spacing-20)
    var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  text-transform: uppercase;
  text-align: center;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;

  &-primary {
    color: var(--done-color-eaeaf6);
    background: linear-gradient(
      var(--done-color-000000),
      var(--done-color-000000) 50%,
      var(--done-color-00ff89) 50%,
      var(--done-color-00ff89)
    );
    background-size: 100% 200%;
    /*transition effect for background*/
    transition: background 0.2s;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      color: var(--done-color-000000);
      background-position: 100% 100%;
      text-decoration: none;
    }

    &[disabled] {
      background: none;
      border: 1px solid var(--done-color-disable);
    }
  }

  &-secondary {
    color: var(--done-color-333834);
    background: linear-gradient(
      var(--done-color-eaeaf6),
      var(--done-color-eaeaf6) 50%,
      var(--done-color-333834) 50%,
      var(--done-color-333834)
    );
    background-size: 100% 200%;
    /*transition effect for background*/
    transition: background 0.2s;
    box-sizing: border-box;

    &:hover,
    &:focus {
      color: var(--done-color-eaeaf6);
      background-position: 100% 100%;
      border: 0.2rem solid var(--done-color-eaeaf6);
      box-sizing: border-box;
      text-decoration: none;
    }
  }

  &-medium {
    width: 28rem;
  }

  &-large {
    max-width: 375px;
    width: 375px;
  }

  &-fast {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.done {
  &-title {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
      var(--done-font-size-140) / var(--done-line-spacing-130)
      var(--done-font-family-signal-mono);
    color: var(--done-color-eaeaf6);
    text-align: center;
    max-width: 85rem;
  }

  &-subtitle {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
      var(--done-font-size-20) / var(--done-line-spacing-25)
      var(--done-font-family-pp-neue-montreal);
    letter-spacing: var(--done-character-spacing--0-2);
  }

  &-color {
    &-white {
      color: var(--done-color-ffffff);
    }
  }

  &-modal {
    &-section {
      &-header {
        padding: 3rem 6rem;
        height: 25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        &-dark {
          background: var(--done-color-000000) 0% 0% no-repeat padding-box;
          color: var(--done-color-ffffff);
        }

        &-image {
          height: 25rem;
          margin-top: 6rem;
        }

        &-callback {
          background-image: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.67) 0%,
              rgba(0, 0, 0, 0) 150%
            ),
            url('../assets/img/callback.webp');
          background-size: cover;
        }
      }
    }

    &-close {
      color: var(--done-color-000000);
      position: absolute;
      top: 1.6rem;
      right: 0.1rem;
      width: 41px;
      height: 41px;

      &-callback {
        color: var(--done-color-ffffff);
      }

      & svg {
        width: 100%;
        height: 100%;
      }

      & svg use {
        fill: var(--done-color-000000);
      }
    }

    &-submit {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 6rem 0 2rem;
      padding: 1rem;

      button {
        margin: 0;
        padding: 0;
        width: 100%;
        max-width: 100%;
      }

      & svg {
        width: 100%;
        height: 100%;
      }

      & svg use {
        fill: var(--done-color-000000);
      }

      &-newsletter {
        &:disabled {
          color: var(--done-color-disable);
          cursor: not-allowed;
        }
      }
    }

    &-content {
      background-color: var(--done-color-00ff89);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
      max-width: 100%;
      min-height: 60rem;
      width: 100%;
      overflow-x: hidden;

      &-white {
        background: var(--done-color-ffffff);
        color: var(--done-color-000000);
      }

      &-section {
        padding: 1.6rem 1.6rem 0;
      }
      .submitted-message {
        text-transform: uppercase;
        text-align: center;
        margin-top: 3rem;
      }

      &-title {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-80) /
          var(--done-line-spacing-80) var(--done-font-family-signal-mono);
        color: var(--done-color-eaeaf6);
        text-align: center;
        vertical-align: middle;
        display: inline-block;

        svg {
          height: 0.75em;
          width: 3rem;
        }
      }

      &-economy {
        text-align: center;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-50) /
          var(--done-line-spacing-55) var(--done-font-family-signal-mono);
      }

      &-subtitle {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-16) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--1-4);
        text-align: left;
        padding: 1rem 0;
      }

      &-notice {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-16) /
          var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-16);
        padding: 3.2rem 0 0;
      }

      &-paragraph {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-16) /
          var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-16);
        text-align: left;
      }

      &-note {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-14) /
          var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-14);
        text-align: left;
        font-style: italic;
      }
    }

    &-newsletter {
      &-content {
        padding: 2rem 7rem;
      }

      &-title {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-16) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        text-transform: uppercase;
      }
    }
  }

  &-input-group {
    display: flex;
    flex-wrap: wrap;
    height: calc(50% + 3.2rem);
    justify-content: center;

    &-newsletter {
      justify-content: flex-start;
      height: unset;

      label {
        margin-bottom: 1rem;
        text-transform: uppercase;
      }

      &.hs-form {
        justify-content: space-around;
        gap: 2rem;
        padding: 3rem 6rem;
      }

      .hs-richtext {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-20) /
          var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);

        a {
          text-decoration: underline;
        }
      }

      .hs-email {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;

        .done-input-group-input {
          margin: 0;
          background: var(--done-color-ffffff);
          text-transform: uppercase;
          padding-left: 2.2rem;
        }
      }
    }

    &-input,
    .hs-input {
      border: 0.1rem solid var(--done-color-000000);
      background: var(--done-color-eaeaf6);
      height: 7.5rem;
      width: 100%;
      margin: 3.2rem 0;
      display: flex;
      flex-direction: row;
      padding-right: 2.2rem;
      align-items: center;

      &-secondary {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 7.5rem;
        width: 100%;
        gap: 2rem;
        margin: 3.2rem 0;
        padding-right: 2.2rem;
        background: var(--done-color-eaeaf6);
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
        border: 0.1rem solid transparent;

        &:focus-within {
          border-color: var(--done-color-000000);
        }

        > input {
          min-width: 100px;

          &::placeholder {
            color: var(--done-color-000000);
            opacity: 0.22;
          }
        }

        > textarea {
          height: 100%;

          &::placeholder {
            color: var(--done-color-000000);
            opacity: 0.21;
          }
        }
      }

      &-newsletter {
        margin-top: 0;
      }

      &.has-danger {
        border-color: var(--done-color-danger);
        color: var(--done-color-danger);

        & svg {
          fill: var(--done-color-danger);
        }

        & input,
        &-icon,
        & input::placeholder {
          color: var(--done-color-danger);
        }
      }

      &-textarea {
        min-height: 16rem;
        align-items: start;
        height: initial;

        textarea {
          resize: none;
        }
      }

      &-callback {
        margin: 1.6rem 0;
      }

      &:focus-within {
        outline: 0.1rem solid var(--done-color-000000);
      }

      &-style {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-2);
        color: var(--done-color-000000);
        padding-inline-start: 2.2rem;
        border: none;
        flex: 1;
        text-transform: uppercase;

        &:focus {
          outline: none;
        }

        &-textarea {
          padding-block: 1.6rem;
        }
      }

      button:focus,
      button:hover {
        text-decoration: underline;
      }

      &-icon {
        display: flex;
        align-items: center;
        gap: 3.2rem;

        &:disabled {
          color: var(--done-color-disable);
          cursor: not-allowed;
          text-decoration: none;
        }

        svg {
          width: 3.9rem;
        }

        &:focus,
        &:hover,
        &:active {
          text-decoration: underline;
        }
      }
    }

    &-asterisk {
      font: italic normal var(--done-font-weight-medium)
        var(--done-font-size-16) / var(--done-line-spacing-20)
        var(--done-font-family-pp-neue-montreal);
      text-align: left;
    }
  }

  &-nav {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: var(--done-color-eaeaf6);
    text-transform: uppercase;
    max-width: 192rem;
    opacity: 1;
    position: fixed;
    z-index: 4;
    width: 100%;
    padding: 1.6rem 2rem;
    top: 0;
    transition: all 0.7s;
    align-items: baseline;

    &-account {
      display: flex;
      align-items: center;

      svg {
        width: 3.9rem;
        height: 3.9rem;
        margin-right: 1.6rem;
      }
    }

    > a.logo {
      width: 15.1rem;
      margin-right: 2.4rem;

      > svg {
        width: 100%;
      }
    }

    &-dark {
      color: var(--done-color-000000);
    }

    &-dark.done-nav-scroll-up {
      background: var(--done-color-ffffff);
    }

    &-dark.done-nav-scroll-down {
      background: var(--done-color-ffffff);
    }

    &-scroll {
      &-down {
        top: -15.1rem;
        background: var(--done-color-eaeaf6);
        color: var(--done-color-000000);
        transition: all 0.7s;

        > a.logo {
          width: 8.6rem;
        }

        .done-nav-mobile {
          height: 3.5rem;
          transition: all 0.7s;

          &-burger {
            width: 4rem;
            transition: all 0.7s;
          }
        }
      }

      &-up {
        top: 0;
        background: var(--done-color-eaeaf6);
        color: var(--done-color-000000);
        transition: all 0.7s;

        > a.logo {
          width: 8.6rem;
        }

        .done-nav-mobile {
          height: 3.5rem;
          transition: all 0.7s;

          &-burger {
            width: 4rem;
            transition: all 0.7s;
          }
        }
      }
    }

    &-links {
      display: none;
      flex-basis: 100%;
      justify-content: space-between;
      align-items: center;
    }

    &-resources {
      padding: 2.4rem 0;

      &:hover,
      &:active,
      &:focus-within {
        color: var(--done-color-000000);

        .done-subnav {
          top: 0;
        }

        svg {
          transform: rotate(-180deg);
        }
      }
    }

    &-link {
      transition: 0.5s;

      &-within {
        display: flex;
        align-items: center;
        gap: 1.2rem;

        svg {
          width: 2rem;
          transform: rotate(0);
          transition: 0.3s;
        }
      }

      .done-subnav {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        padding-top: 12.7rem;
        background-color: var(--done-color-eaeaf6);
        color: var(--done-color-000000);
        width: 22.2rem;
        top: -430%;
        z-index: -1;
        transition: 0.5s;
        margin-left: -3.6rem;

        &-link {
          padding: 2.4rem 3.6rem;

          &:hover,
          &:active,
          &:focus {
            background-color: #00ff89;
          }
        }
      }
    }

    &-mobile {
      height: 5.1rem;
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      &-link {
        width: 80%;
        border-bottom: var(--done-color-eaeaf6) 0.1rem solid;
        height: 100%;
        padding: 4.2rem 0 1.2rem 1.2rem;

        &-within {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1.2rem;

          svg {
            width: 2rem;
            transform: rotate(0);
            transition: 0.3s;
          }

          &.active svg {
            transform: rotate(-180deg);
          }
        }
      }

      &-burger {
        width: 5.5rem;
      }

      &-menu {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 8;
        z-index: 5;
        overflow-y: scroll;
      }

      &-open {
        display: inline-flex;
        width: 100%;
        padding: 1.6rem 2rem;
        justify-content: space-between;
        align-items: center;

        > a.logo {
          width: 15.1rem;
          margin-right: 2.4rem;

          > svg {
            width: 100%;
          }
        }

        > button > svg {
          width: 4rem;
        }
      }

      &-links {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: left;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-2);
        min-height: 52rem;
      }

      &-account {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10.2rem;
        padding-top: 4.8rem;

        svg {
          width: 4rem;
          height: 4rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &-subnav {
    &-mobile {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      max-height: 0;
      overflow: hidden;
      transition: 0.3s ease;

      &-link {
        margin-top: 1.2rem;
      }

      &.active {
        max-height: 30rem;
        transition: 0.3s ease;
      }

      &-link {
        padding: 1.2rem 2.4rem;
      }
    }
  }

  &-section {
    padding: 6.8rem 2.8rem;
    width: 100%;

    &-light {
      background: var(--done-color-eaeaf6) 0% 0% no-repeat padding-box;
      color: var(--done-color-000000);
    }

    &-dark {
      background: var(--done-color-333834) 0% 0% no-repeat padding-box;
      color: var(--done-color-eaeaf6);
      fill: var(--done-color-eaeaf6);
    }

    &-white {
      background: var(--done-color-ffffff) 0% 0% no-repeat padding-box;
      color: var(--done-color-000000);
    }

    &-modal {
      position: relative;
    }

    &-margin-button {
      max-width: 80%;
      margin: auto;
    }

    &-title {
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-100) / var(--done-font-size-100)
        var(--done-font-family-signal-mono);
      font-size: var(--done-font-size-60);
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-simulator {
        display: block;
      }

      &-vision-svg {
        display: flex;
        align-self: flex-end;
        height: 7.2rem;
      }

      &-svg {
        height: 7.2rem;
        align-self: flex-start;
      }

      &-shared {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 3.2rem;

        p {
          flex-basis: 25%;
        }
      }

      &-second {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-50) /
          var(--done-line-spacing-55) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-5);
        margin: 4.4rem 0;

        &-vision {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-32) /
            var(--done-line-spacing-28) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-32);
          margin: 4.4rem 0;
        }
      }

      &-third {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-32) /
          var(--done-line-spacing-28) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-32);
        text-align: center;

        svg {
          height: 2rem;
        }
      }

      &-fourth {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-22) /
          var(--done-line-spacing-28) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-22);
        text-align: left;

        > svg {
          height: 1.5rem;
        }
      }

      &-timeline {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &-center {
        text-align: center;
        padding: 0 1rem;
      }
    }

    &-subtitle {
      font: var(--done-font-style-normal) normal var(--done-font-weight-medium)
        var(--done-font-size-22) / var(--done-line-spacing-30)
        var(--done-font-family-pp-neue-montreal);
      letter-spacing: var(--done-character-spacing--0-22);
      text-align: center;
      opacity: 1;
      padding: 3.6rem 0;
      width: 100%;
    }

    &-step {
      display: flex;
      flex-direction: column;
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-16) / var(--done-line-spacing-20)
        var(--done-font-family-signal-mono);
      overflow: hidden;

      &-one {
        margin-bottom: 3rem;
        width: calc(6rem * 2.865);

        img {
          aspect-ratio: auto 573/200;
        }
      }

      &-image {
        height: 6rem;
      }

      &-two {
        width: calc(6rem * 3.797);
        align-self: flex-end;
        text-align: end;

        img {
          aspect-ratio: auto 760/200;
        }
      }

      &-three {
        width: calc(6rem * 4.113);

        img {
          aspect-ratio: auto 827/200;
        }
      }

      &-title {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-50) /
          var(--done-line-spacing-55) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-5);
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 8rem;
        padding-top: 3.6rem;
      }

      &-subtitle {
        margin-top: 1.8rem;
        font-size: 2.2rem;
      }

      > div {
        margin: 1.6rem 0;
      }

      > .done-section-step-unreveal {
        margin-left: -50%;
      }

      > .done-section-step-unreveal-right {
        margin-right: -50%;
        margin-left: 0;
      }

      > .done-section-step-reveal {
        transition: 0.5s;
        margin-left: 0;
        margin-right: 0;
      }
    }

    &-button-group {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1.6rem;
      width: 100%;

      .done-section-step & {
        margin-top: 4rem;
      }
    }

    &-photos {
      display: inline-flex;
      max-height: 60rem;

      img {
        aspect-ratio: auto 6/7;
      }

      &-mobile {
        display: block;
        width: 100%;
        object-fit: cover;
      }

      &-desktop {
        display: none;
        width: 33%;
        object-fit: cover;
      }
    }

    &-mini {
      display: flex;
      padding: 6.8rem 0;
      justify-content: space-between;
      flex-direction: column;

      &-title {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-50) /
          var(--done-line-spacing-55) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-5);
        margin-bottom: 6rem;
      }

      &-picture {
        display: flex;

        svg {
          display: none;
        }

        &-image {
          width: 100%;
          max-width: 46rem;
          max-height: 46rem;
        }
      }

      &-text {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-22) /
          var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-22);
        align-self: flex-end;
        padding: 3.6rem 0 0;
      }

      &-description {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-22) /
          var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-22);
        align-self: flex-end;

        p:not(:first-child) {
          margin-top: 2.4rem;
        }
      }
    }

    &-sub {
      display: flex;
      align-items: stretch;
      flex-direction: column;

      &-part {
        flex-basis: 100%;

        &:not(:first-child) {
          text-align: center;
          text-align: -webkit-center;
        }

        &:nth-child(2) {
          align-self: center;
        }

        &-icon {
          &-desktop {
            display: none;

            &-small {
              display: none;
              width: 10rem;
            }
          }

          &-mobile {
            display: block;
            margin-top: 7.2rem;
            width: 7.2rem;
            align-self: center;
          }
        }
      }

      &-impact {
        &-list {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          text-align: center;
        }

        &-detail {
          margin-top: 7.5rem;

          &-number {
            font: var(--done-font-style-normal) normal
              var(--done-font-weight-normal) var(--done-font-size-50) /
              var(--done-line-spacing-80) var(--done-font-family-signal-mono);
            letter-spacing: var(--done-character-spacing--0-5);
          }

          &-text {
            font: var(--done-font-style-normal) normal
              var(--done-font-weight-normal) var(--done-font-size-32) /
              var(--done-line-spacing-25) var(--done-font-family-signal-mono);
          }
        }
      }
    }

    &-avantages {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 2.4rem;

      &-text {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-100) /
          var(--done-font-size-100) var(--done-font-family-signal-mono);
        color: var(--done-color-000000);
        width: 100%;

        &-associations {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-50) /
            var(--done-line-spacing-55) var(--done-font-family-signal-mono);
        }
      }

      &-text > span {
        color: var(--done-color-00ff89);
      }

      &-list {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-32) /
          var(--done-line-spacing-28) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-32);
        color: var(--done-color-000000);

        > ul {
          margin-bottom: 8rem;
        }

        &-text {
          margin-bottom: 2.5rem;
          cursor: pointer;
          color: var(--done-color-000000);

          &:hover,
          &:focus {
            color: var(--done-color-00ff89);
          }
        }

        &-text.active {
          text-decoration: underline;
        }

        &-description {
          display: none;
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-medium) var(--done-font-size-16) /
            var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
          color: var(--done-color-000000);
          margin-bottom: 2.5rem;
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        &-description.visible {
          display: block;
          animation: fadeIn 2s;
          height: 100%;
          overflow: hidden;
        }
      }

      &-arrow {
        display: none;

        > img {
          width: 9.2rem;
        }
      }
    }

    &-pig {
      background: var(--done-color-00ff89) 0% 0% no-repeat padding-box;
      height: 7.5rem;
      overflow: hidden;
      --offset: 20vw;
      --move-initial: calc(-25% + var(--offset));
      --move-final: calc(-50% + var(--offset));

      &-inner {
        display: flex;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-32) /
          var(--done-line-spacing-30) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-5);
        color: var(--done-color-000000);
        width: 800%;
        height: 100%;
        position: relative;
        transform: translate3d(var(--move-initial), 0, 0);
        animation: marquee 8s linear infinite;

        &:hover {
          animation-play-state: paused;
        }
      }

      &-animate {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-grow: 2;

        svg {
          width: 6rem;
        }
      }
    }

    &-promise {
      background: var(--done-color-ffffff) 0% 0% no-repeat padding-box;
      height: 7.5rem;
      overflow: hidden;
      --offset: 20vw;
      --move-initial: calc(-25% + var(--offset));
      --move-final: calc(-50% + var(--offset));

      &-inner {
        display: flex;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-32) /
          var(--done-line-spacing-30) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-5);
        color: var(--done-color-000000);
        width: 800%;
        height: 100%;
        position: relative;
        transform: translate3d(var(--move-initial), 0, 0);
        animation: marquee 8s linear infinite;

        &:hover {
          animation-play-state: paused;
        }
      }

      &-animate {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-grow: 2;

        svg {
          width: 5rem;
          margin-right: 1.1rem;
        }

        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    &-cta {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &-title {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-32) /
          var(--done-line-spacing-55) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-5);
        flex-basis: 100%;
        text-align: center;
      }

      &-logo {
        height: 12.5rem;
        margin: 7rem 0;
      }
    }

    &-timeline {
      display: flex;
      flex-direction: row;

      &-title {
        display: flex;
        justify-content: space-between;
        margin: 4.4rem 0 0;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-32) /
          var(--done-line-spacing-28) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-32);
        color: var(--done-color-000000);
        justify-content: flex-start;
        flex-direction: column;

        &-detail {
          width: 100%;
          margin: 1.6rem;
          opacity: 0;
          transition: 1s all ease;

          &-visible {
            opacity: 1;
          }

          br {
            display: none;
          }
        }
      }

      &-description {
        display: none;
        justify-content: space-between;
        margin: 4.4rem 0;

        &-detail {
          width: 100%;
          margin: 1.6rem 0;
          min-height: 27rem;
        }
      }

      &-list {
        display: flex;
        justify-content: space-between;
        margin: 6rem 1.6rem 0 0;
        flex-direction: column;
        order: -1;
      }

      &-detail {
        flex-grow: 1;
        position: relative;

        &:before {
          content: '';
          display: block;
          border: 0.1rem solid var(--done-color-000000);
          width: 4.4rem;
          height: 4.4rem;
          box-sizing: border-box;
          cursor: pointer;
        }

        &:hover {
          &::before {
            background: var(--done-color-000000);
          }
        }

        &-active {
          &::before {
            background: var(--done-color-00ff89) 0% 0% no-repeat padding-box;
            border: none;
          }
        }

        &:after {
          content: '';
          position: absolute;
          border-left: 0.125rem dashed var(--done-color-000000);
          width: 0.1rem;
          height: calc(100% - 4.4rem);
          position: absolute;
          top: 4.4rem;
          left: 2.2rem;
        }
      }
    }

    &-button {
      margin: 6.6rem 0 0;
    }

    &-paragraph {
      font: var(--done-font-style-normal) normal var(--done-font-weight-medium)
        var(--done-font-size-22) / var(--done-line-spacing-30)
        var(--done-font-family-pp-neue-montreal);
      letter-spacing: var(--done-character-spacing--0-22);
      color: var(--done-color-000000);
      text-align: left;
    }

    &-form {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      gap: 2.4rem;

      &-group {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-25) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-2);
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        flex-basis: 100%;
        width: 100%;

        &-paragraph {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-medium) var(--done-font-size-22) /
            var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
          letter-spacing: var(--done-character-spacing--0-22);
          color: var(--done-color-000000);
          text-align: left;
        }
      }

      &-button {
        flex-basis: 100%;
        padding: 7.5rem 0 0;
        display: flex;
        justify-content: center;
      }
    }

    &-cover {
      padding: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    &-company {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 2.4rem 1.6rem;
      gap: 2.4rem;
    }

    &-stories {
      display: flex;
      align-items: stretch;

      &-container {
        position: relative;
        background:
          linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.2) 100%
          ),
          url('../assets/img/stories_photo.webp') center bottom / cover
            no-repeat;
        width: 100%;
        padding: 2.8rem 2.8rem 11.3rem;
        min-height: 50vh;
      }

      &-horizontal-container {
        display: flex;
        height: 100%;
      }

      &-photo {
        display: none;
        background:
          linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.2) 100%
          ),
          url('../assets/img/stories_photo.webp') center bottom / cover
            no-repeat;
        flex: 50%;
      }

      &-sub-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        flex: 50%;
        width: 100%;
      }

      &-top {
        flex: 1;
      }

      &-bottom {
        text-align: center;

        .done-page {
          &-details {
            display: flex;
            flex-direction: column;
            padding: 0;
          }

          &-stories {
            &-destination {
              text-align: center;

              &-details {
                max-width: 22.2rem;
                margin: 0 auto 3.8rem;
                font: var(--done-font-style-normal) normal
                  var(--done-font-weight-normal) var(--done-font-size-16) /
                  var(--done-line-spacing-20)
                  var(--done-font-family-pp-neue-montreal);
              }
            }

            & .btn {
              margin: 0 auto;
            }
          }
        }
      }

      &-arrow {
        display: none;
      }

      &-title {
        max-width: 25rem;
        margin: 7rem auto 3.7rem;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-50) /
          var(--done-line-spacing-55) var(--done-font-family-signal-mono);
        text-align: center;
        text-transform: uppercase;
      }

      &-subtitle {
        max-width: 31rem;
        margin: 0 auto;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
        text-align: center;
      }
    }

    &-half {
      display: flex;
      flex-direction: column;

      &-one {
        width: 100%;

        &-img {
          width: 100%;
          height: 100%;
        }
      }

      &-two {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3.2rem 1.6rem;
        width: 100%;
        margin: 0 auto;
      }

      &-title {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-50) /
          var(--done-line-spacing-55) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-5);
        margin-bottom: 3.2rem;
      }

      &-description {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-20) /
          var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-2);

        p:not(:first-child) {
          margin-top: 2.4rem;
        }
      }
    }

    &-faq {
      padding: 3.2rem 0;
      width: 100%;

      &-home {
        padding-bottom: 0;
      }

      &-title {
        text-align: center;
      }

      &-group {
        height: 11.8rem;
        min-height: 11.8rem;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-25) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-2);
        border-top: var(--done-color-eaeaf6) 0.1rem solid;
        border-bottom: var(--done-color-eaeaf6) 0.1rem solid;
        box-sizing: border-box;
        padding: 0 1.6rem;
        cursor: pointer;

        &-light {
          border-top: var(--done-color-333834) 0.1rem solid;
          border-bottom: none;
        }

        &[open] {
          height: auto;

          svg {
            rotate: 180deg;
            transition: rotate 0.7s;
          }
        }

        ul {
          padding-bottom: 1.6rem;
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-20) /
            var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);

          li {
            list-style: disc;
          }
        }

        svg {
          height: 3.8rem;
          width: 3.8rem;
          flex-shrink: 0;
          rotate: 360deg;
          transition: rotate 0.7s;
        }

        &-collapse {
          width: 100%;
          height: 11.8rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
        }

        &-title {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-20) /
            var(--done-line-spacing-28) var(--done-font-family-signal-mono);
          text-transform: uppercase;
        }

        &-paragraph {
          padding-bottom: 1.6rem;
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-20) /
            var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
        }

        &-more {
          display: none;
        }
      }
    }

    // fin de la reprise

    &-button {
      margin: 6.6rem 0 0;
      max-width: 100%;
      display: flex;
    }

    &-paragraph {
      font: var(--done-font-style-normal) normal var(--done-font-weight-medium)
        var(--done-font-size-22) / var(--done-line-spacing-30)
        var(--done-font-family-pp-neue-montreal);
      letter-spacing: var(--done-character-spacing--0-22);
      color: var(--done-color-000000);
      text-align: left;

      &-second {
        margin-top: 2.4rem;
      }
    }

    &-unsold {
      display: flex;
      flex-wrap: wrap;

      &-title {
        text-align: center;
        margin-bottom: 6.6rem;
      }

      &-button {
        display: none;
        padding-top: 7.6rem;
        margin-bottom: 7.6rem;
        width: 100%;

        &-mobile {
          display: flex;
          justify-content: center;
          margin-top: 6.6rem;
        }
      }

      &-first {
        display: flex;
        flex-wrap: wrap;
      }

      &-second {
        text-align: center;

        &-title {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-32) /
            var(--done-line-spacing-28) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-32);
          margin-bottom: 1.5rem;
        }

        &-text {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-medium) var(--done-font-size-20) /
            var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
        }
      }
    }

    &-impact {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &-title {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-32) /
          var(--done-line-spacing-28) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-32);
        text-align: center;
      }

      &-list {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        padding: 10rem 0 0;
        flex-basis: 100%;
      }

      &-detail {
        text-align: center;
        margin-bottom: 6.8rem;

        &:last-child {
          margin-bottom: 0;
        }

        svg {
          width: 10rem;
        }

        &-number {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-50) /
            var(--done-line-spacing-55) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
          padding: 0;
        }

        &-text {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-20) /
            var(--done-line-spacing-25) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
        }
      }
    }
  }

  &-page {
    &-stories {
      margin: 3.2rem 0;

      &-destination {
        text-align: left;

        &-details {
          max-width: 22.2rem;
          margin: 0 auto 3.8rem;
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-16) /
            var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
        }

        &-span {
          grid-column: 1 / span 2;
        }
      }
    }
  }
}

.section-header {
  min-height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  overflow: hidden;

  &-vision {
    flex-direction: column;
    background-image: url('../assets/img/animated-picto-green.gif');
    background-position: center 26%;
    background-size: 40rem;
    position: relative;

    @media only screen and (min-width: base.$x-small-devices) {
      background-size: 35rem;
    }

    &-arrow {
      position: absolute;
      left: inherit;
      bottom: 3rem;
      width: 7.2rem;
    }
  }

  &-video {
    position: absolute;
    min-height: 76.8rem;
    height: 100vh;
    z-index: 1;
    background: var(--done-color-eaeaf6);
    width: inherit;

    &-background {
      position: absolute;
      width: 100%;
      height: 100%;
      min-height: 100vh;
      background: transparent
        linear-gradient(180deg, #000000aa 0%, #00000000 100%) 0% 0% no-repeat
        padding-box;
      opacity: 1;
      z-index: 2;
    }
  }
}

.subsection {
  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    width: 100%;
    padding: 0;
    z-index: 3;

    > svg {
      width: 7.8rem;
      color: var(--done-color-eaeaf6);
    }
  }

  &-social {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    gap: 1.2rem;
    justify-content: space-between;
    padding: 1.6rem 2rem;
    z-index: 3;

    > a {
      height: 3.2rem;
      width: 3.2rem;
    }

    > a > svg {
      width: 100%;
      height: 100%;
      color: var(--done-color-eaeaf6);
    }
  }
}

.done-callback {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;

  &-fix {
    background: var(--done-color-eaeaf6) 0 0 no-repeat padding-box;
    width: 100%;
    height: 8.6rem;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
      var(--done-font-size-20) / var(--done-line-spacing-20)
      var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-2);
    color: var(--done-color-000000);
    text-align: left;
    cursor: pointer;
    z-index: 5;
    bottom: 0;

    svg {
      height: 1em;
      width: 3rem;
    }

    &-dark {
      background: var(--done-color-000000) 0% 0% no-repeat padding-box;
      color: var(--done-color-ffffff);
    }
  }
}

.done-scroll {
  z-index: 100;
  position: fixed;
  height: 1.2rem;
  bottom: 0;
  background: transparent;

  &-plain {
    background: var(--done-color-00ff89);
    height: 1.2rem;
  }

  &-desktop {
    z-index: 1000;
    position: fixed;
    right: 0;
    width: 1.6rem;
    height: 100%;
    bottom: 0;
    display: none;
    flex-direction: column-reverse;

    &-plain {
      background: var(--done-color-00ff89);
      width: 100%;
    }
  }
}

.done-footer {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
    var(--done-font-size-20) / var(--done-line-spacing-20)
    var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 7.6rem;

  &-logos {
    display: flex;
    flex-direction: column-reverse;
  }

  &-contact {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 3.2rem;
  }

  &-arrow {
    width: 29.25%;
    display: none;

    &-mobile {
      width: 100%;
    }
  }

  &-social {
    width: 100%;
    justify-content: space-between;
    display: none;
    text-align: center;
    max-width: 18rem;
    margin-bottom: 2.4rem;

    &-facebook,
    &-linkedin,
    &-youtube,
    &-glassdoor,
    &-instagram {
      width: 20%;

      &-mobile {
        svg {
          height: 1.6rem;
        }
      }
    }

    &-instagram svg {
      height: 3.2rem;
      padding-right: 1rem;
    }

    &-mobile {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 2.9rem;
    }
  }

  &-links {
    line-height: 4.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-link {
    flex-basis: 50%;

    &:nth-child(even) {
      text-align: right;
    }
  }

  &-button {
    height: 15.7rem;
    display: flex;
    justify-content: flex-end;

    &-mobile {
      display: flex;
      justify-content: center;
      height: 10rem;
    }
  }

  &-mentions {
    display: none;
    flex-basis: 50%;

    &-mobile {
      margin: 6rem 0 3.6rem;
      display: flex;
      justify-content: space-between;

      &-social {
        display: flex;
        flex-direction: column;
        width: 8.3rem;
        flex-shrink: 0;
        justify-content: space-between;
      }
    }
  }

  &-legal {
    height: calc(50% - 3.2rem);
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;

    &-mobile {
      display: flex;
      flex-direction: column;
      line-height: 4.2rem;
    }

    &-link {
      text-align: end;

      &-cookie:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      svg {
        height: 1.7rem;
      }
    }
  }
}
.pagination {
  &-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    margin: 4rem auto;
  }
  &-button {
    width: 3rem;
    color: var(--done-color-333834);

    &.disabled {
      svg {
        display: none;
      }
    }

    &:hover,
    &:focus {
      color: var(--done-color-00ff89);
    }

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  &-number {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
      var(--done-font-size-20) / var(--done-line-spacing-20)
      var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-2);
    height: 2.8rem;
    width: 2.8rem;
    text-align: center;

    &:hover,
    &:focus {
      background-color: var(--done-color-00ff89);
      border: 1px solid var(--done-color-00ff89);
      text-decoration: none;
    }
  }

  &-numbers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  &-number {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--done-color-333834);
  }

  &-number.active {
    background-color: var(--done-color-333834);
    color: var(--done-color-ffffff);
    border: 1px solid var(--done-color-333834);

    &:hover,
    &:focus {
      background-color: var(--done-color-00ff89);
      border: 1px solid var(--done-color-00ff89);
    }
  }
}

.done-animated-picto-white {
  flex-direction: column;
  background-image: url('../assets/img/animated-picto-white.gif');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 8.1rem;
  width: 100%;
}

@media only screen and (min-width: base.$medium-devices) {
  .done {
    &-section {
      &-step {
        &-one {
          width: calc(12rem * 2.865);
        }

        &-image {
          height: 12rem;
        }

        &-two {
          width: calc(12rem * 3.797);
        }

        &-three {
          width: calc(12rem * 4.113);
        }

        &-subtitle {
          margin-top: 3.4rem;
          font-size: 2.5rem;
        }
      }
    }
  }
  .subsection {
    &-title {
      gap: 5.8rem;
    }

    &-social {
      padding: 3.2rem 5rem;
    }
  }
}

@media only screen and (min-width: base.$large-devices) {
  svg {
    height: 7.9rem;
  }

  .btn {
    height: 7.5rem;
    max-width: 37rem;
    padding: 0 4rem;

    &-large {
      max-width: 375px;
      width: 375px;
    }
  }

  .done {
    &-modal {
      &-close {
        position: absolute;
        width: 8.4rem;
        height: 6.4rem;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &-submit {
        padding: 2rem 7rem;
      }

      &-content {
        width: auto;

        &-title {
          &-title {
            font: var(--done-font-style-normal) normal
              var(--done-font-weight-normal) var(--done-font-size-140) /
              var(--done-line-spacing-80) var(--done-font-family-signal-mono);
          }
        }

        .hs-input {
          background: var(--done-color-ffffff);
          text-transform: uppercase;
          padding-left: 2.2rem;
          margin: 0;
        }

        &-economy {
          text-align: center;
        }

        &-white {
          max-width: 70rem;
        }

        &-callback {
          min-width: 70rem;
        }

        &-section {
          padding: 1.6rem 5rem 0 5rem;
        }
      }
    }

    &-input-group {
      justify-content: flex-start;

      &-input {
        span {
          display: flex;
        }

        &-icon {
          span {
            display: flex;
          }
        }
      }
    }

    &-nav {
      padding: 3.2rem;
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-16) / var(--done-line-spacing-20)
        var(--done-font-family-signal-mono);
      letter-spacing: var(--done-character-spacing--0-2);
      align-items: center;

      &-account {
        svg {
          width: 3.9rem;
          height: 3.9rem;
          margin-right: 1.6rem;
        }
      }

      > a.logo {
        width: calc(100% / 8);
        margin-right: 3.2rem;
      }

      &-links {
        display: flex;
      }

      &-mobile {
        display: none;
      }

      &-scroll {
        &-down {
          padding: 0 2.8rem;
        }

        &-up {
          padding: 0 2.8rem;
        }
      }
    }

    &-section {
      padding: 4.8rem;
      position: relative;

      &-accueil {
        padding-top: 10.8rem;
      }

      &-title {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-100) /
          var(--done-font-size-100) var(--done-font-family-signal-mono);

        &-simulator {
          max-width: 75%;
        }

        &-vision-svg {
          display: none;
        }

        svg {
          height: 8rem;
        }

        &-second {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-50) /
            var(--done-line-spacing-55) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
          margin: 4.4rem 0;
        }

        &-third {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-32) /
            var(--done-line-spacing-30) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-32);
        }

        &-timeline {
          flex-direction: row;
          align-items: center;
          margin-bottom: 6.6rem;
        }

        &-shared {
          flex-direction: row;
        }
      }

      &-subtitle {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-28) /
          var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-22);
        max-width: 565px;
        padding: 0;
      }

      &-step {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-32) /
          var(--done-line-spacing-28) var(--done-font-family-signal-mono);

        &-one {
          margin-bottom: 10rem;
          width: calc(18rem * 2.865);
        }

        &-image {
          height: 18rem;
        }

        &-two {
          width: calc(18rem * 3.797);
          align-self: flex-end;
        }

        &-three {
          width: calc(18rem * 4.113);
        }

        > div {
          margin: 3.2rem 0;
        }
      }

      &-button-group {
        justify-content: center;
        flex-wrap: nowrap;
        gap: 9.5rem;

        .done-section-step & {
          margin-top: 12rem;
        }
      }

      &-photos {
        &-mobile {
          display: none;
        }

        &-desktop {
          display: block;
          transform: translateY(101%);
          object-fit: cover;

          &-reveal {
            transform: none;
            transition: 0.5s;

            &:nth-child(2) {
              transition-delay: 0.2s;
            }

            &:nth-child(3) {
              transition-delay: 0.4s;
            }
          }
        }
      }

      &-cta {
        background: var(--done-color-333834) 0% 0% no-repeat padding-box;
        color: var(--done-color-eaeaf6);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 10rem;

        &-title {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-50) /
            var(--done-line-spacing-55) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
          flex-basis: 100%;
          text-align: center;
        }

        &-logo {
          margin: 10rem 0;
          height: 15.5rem;
          max-width: 230px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      &-timeline {
        flex-direction: column;

        &-title {
          display: flex;
          justify-content: flex-start;
          margin: 4.4rem 0;
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-32) /
            var(--done-line-spacing-28) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-32);
          color: var(--done-color-000000);
          flex-direction: row;

          &-detail {
            width: 25%;
            display: none;
            margin: 0;
            opacity: 1;

            br {
              display: block;
            }

            &-active {
              display: block;
            }

            &-hover {
              display: block;
            }
          }
        }

        &-description {
          display: flex;
          justify-content: flex-start;
          margin: 4.4rem 0 0;

          &-detail {
            width: calc(25% - 1.6rem);
            margin-right: 1.6rem;
            padding-right: 5rem;
            display: none;

            &-active {
              display: block;
            }

            &-hover {
              display: block;
            }
          }
        }

        &-list {
          display: flex;
          justify-content: space-between;
          margin: 1rem 0 0;
          flex-direction: row;
          order: 0;
        }

        &-detail {
          flex-grow: 1;
          position: relative;

          &:before {
            content: '';
            display: block;
            border: 0.1rem solid var(--done-color-000000);
            width: 4.4rem;
            height: 4.4rem;
            box-sizing: border-box;
            cursor: pointer;
          }

          &-active {
            &::before {
              background: var(--done-color-00ff89) 0% 0% no-repeat padding-box;
              border: none;
            }
          }

          &:after {
            content: '';
            position: absolute;
            height: 0.1rem;
            border-top: 0.125rem dashed var(--done-color-000000);
            top: 2.2rem;
            left: 4.4rem;
            width: calc(100% - 4.4rem);
            border-left: none;
          }
        }
      }

      &-button {
        margin: 6.6rem 0 0;
      }

      &-paragraph {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-22) /
          var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-22);
        color: var(--done-color-000000);
        text-align: left;
      }

      &-form {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        gap: 0;

        &-group {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-20) /
            var(--done-line-spacing-25) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-2);
          flex-basis: calc(50% - 2.5rem);
          gap: 1.6rem;

          &.center {
            margin: 0 auto;
          }

          label {
            margin-top: 2rem;
          }
        }

        &-button {
          flex-basis: 100%;
          padding: 7.5rem 0 0;
          display: flex;
          justify-content: center;
        }
      }

      &-cover {
        padding: 0;
        width: 100%;
        overflow: hidden;
      }

      &-unsold {
        gap: 2.4rem;

        &-first {
          width: 50%;
        }

        &-title {
          text-align: left;
          margin-bottom: 0;
          max-width: 650px;
          font-size: var(--done-font-size-80);
          line-height: var(--done-line-spacing-80);
        }

        &-button {
          display: block;
          padding-top: 7.6rem;
          margin-bottom: 0;

          &-mobile {
            display: none;
          }
        }

        &-second {
          flex-basis: 450px;
          text-align: left;
          margin-left: 5rem;

          &-title {
            margin-top: 15rem;
          }
        }
      }

      &-impact {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &-title {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-50) /
            var(--done-line-spacing-55) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
          text-align: center;
        }

        &-list {
          display: flex;
          flex-wrap: nowrap;
          flex-basis: 100%;
        }

        &-detail {
          text-align: center;
          margin-bottom: 0;

          svg {
            width: 10rem;
          }

          &-number {
            font: var(--done-font-style-normal) normal
              var(--done-font-weight-normal) var(--done-font-size-50) /
              var(--done-line-spacing-55) var(--done-font-family-signal-mono);
            letter-spacing: var(--done-character-spacing--0-5);
            padding: 1.6rem 0 2.4rem;
          }

          &-text {
            font: var(--done-font-style-normal) normal
              var(--done-font-weight-normal) var(--done-font-size-20) /
              var(--done-line-spacing-25) var(--done-font-family-signal-mono);
            letter-spacing: var(--done-character-spacing--0-5);
          }
        }
      }

      &-company {
        height: 11rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 10rem;
        background-color: var(--done-color-00ff89);
      }

      &-mini {
        padding: 6.8rem 0;
        flex-direction: row;

        &-picture {
          svg {
            width: 12.1rem;
            display: block;
          }

          &-image {
            max-width: 64rem;
            max-height: 64rem;
            margin-left: 5rem;
          }
        }

        &-text {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-medium) var(--done-font-size-22) /
            var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
          letter-spacing: var(--done-character-spacing--0-22);
          align-self: center;
          padding: 0 12rem 0 1rem;
          flex: 1;
          max-width: 60rem;
        }

        &-description {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-medium) var(--done-font-size-22) /
            var(--done-line-spacing-30) var(--done-font-family-pp-neue-montreal);
          letter-spacing: var(--done-character-spacing--0-22);
          align-self: flex-end;
        }
      }

      &-sub {
        align-items: stretch;
        flex-direction: row;

        &-part {
          flex-basis: 33.33%;

          &-icon {
            &-desktop {
              display: block;

              &-small {
                display: block;
              }
            }

            &-mobile {
              display: none;
            }
          }
        }

        &-impact {
          &-detail {
            margin-top: 0;

            &-number {
              font: var(--done-font-style-normal) normal
                var(--done-font-weight-normal) var(--done-font-size-50) /
                var(--done-line-spacing-80) var(--done-font-family-signal-mono);
              letter-spacing: var(--done-character-spacing--0-5);
            }

            &-text {
              font: var(--done-font-style-normal) normal
                var(--done-font-weight-normal) var(--done-font-size-32) /
                var(--done-line-spacing-25) var(--done-font-family-signal-mono);
            }
          }
        }
      }

      &-avantages {
        flex-direction: row;

        &-text {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-100) /
            var(--done-font-size-100) var(--done-font-family-signal-mono);
          width: 50%;
        }

        &-text > span {
          color: var(--done-color-00ff89);
        }

        &-list {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-32) /
            var(--done-line-spacing-28) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-32);
          width: 35%;
          padding-top: 1.5rem;

          > ul {
            margin-bottom: 8rem;
          }

          &-text {
            margin-bottom: 2.5rem;
            cursor: pointer;

            &:hover,
            &:focus {
              color: var(--done-color-00ff89);
            }
          }

          &-description {
            display: none;
            font: var(--done-font-style-normal) normal
              var(--done-font-weight-medium) var(--done-font-size-20) /
              var(--done-line-spacing-28)
              var(--done-font-family-pp-neue-montreal);
            color: var(--done-color-000000);
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }

          &-description.visible {
            display: block;
            animation: fadeIn 2s;
          }
        }

        &-arrow {
          display: block;
          padding-top: 1.5rem;

          > svg {
            width: 9.2rem;
          }
        }
      }

      &-pig {
        height: 16.6rem;

        &-inner {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-50) /
            var(--done-line-spacing-55) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
          width: 400%;
        }

        &-animate {
          svg {
            width: 10rem;
          }
        }
      }

      &-promise {
        height: 10.6rem;

        &-inner {
          display: flex;
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-50) /
            var(--done-line-spacing-55) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
          color: var(--done-color-000000);
          width: 400%;
        }

        &-animate {
          svg {
            width: 8rem;
          }
        }
      }

      &-faq {
        padding-bottom: 10rem;
        width: 100%;

        &-home {
          padding-bottom: 0;
        }

        &-title {
          text-align: center;
        }

        &-group {
          height: 11.8rem;
          padding: 0 10rem;

          svg {
            height: 3.8rem;
          }

          &-collapse {
            height: 11.8rem;
          }

          &-paragraph {
            padding-bottom: 3.2rem;
          }
        }
      }

      &-stories {
        flex-direction: row;
        background: var(--done-color-00ff89);

        &:hover,
        &-hover {
          .done-section-stories-container {
            margin-left: 0;
            transition: 0.5s;
            background-color: var(--done-color-eaeaf6);
          }

          .done-section-stories-sub-container {
            transform: none;
            transition: 0.4s;
            transition-delay: 0.2s;
          }
        }

        &-photo {
          display: revert;
        }

        &-container {
          background: none;
          overflow: hidden;
          padding: 0;
          margin-left: -200%;
          transition: 0.5s;
        }

        &-sub-container {
          align-items: flex-start;
          padding: 5rem;
          transform: translateX(150%);
        }

        &-title {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-140) /
            var(--done-line-spacing-130) var(--done-font-family-signal-mono);
          color: var(--done-color-eaeaf6);
          letter-spacing: -0.15rem;
          text-transform: uppercase;
          text-align: left;
          line-height: 1;
          max-width: 41.5rem;
          margin: 0 0 6.1rem;
        }

        &-subtitle {
          max-width: 50rem;
          margin-bottom: 6.1rem;
          line-height: var(--done-line-spacing-28);
          text-align: left;
          font-size: var(--done-font-size-18);
        }

        &-bottom {
          width: 100%;

          .done-page {
            &-stories {
              display: grid;
              grid-template-columns: 0 35.8rem;
              justify-content: unset;
              padding: 0;

              &-destination {
                text-align: left;

                span {
                  font: var(--done-font-style-normal) normal
                    var(--done-font-weight-normal) var(--done-font-size-28) /
                    var(--done-line-spacing-28)
                    var(--done-font-family-signal-mono);
                  letter-spacing: var(--done-character-spacing--0-32);
                  line-height: var(--done-line-spacing-30);
                  margin-bottom: 2.9rem;
                }

                &-details {
                  margin: 0;
                  max-width: unset;
                  font-size: var(--done-font-size-14);
                  line-height: var(--done-line-spacing-25);
                }
              }

              & .btn {
                margin: 6rem 0 0;
              }
            }
          }
        }
      }

      &-half {
        flex-direction: row;

        &-one {
          width: 50%;
        }

        &-two {
          padding: 3.2rem;
          width: 50%;
          max-width: 46rem;
        }

        &-title {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-50) /
            var(--done-line-spacing-55) var(--done-font-family-signal-mono);
          letter-spacing: var(--done-character-spacing--0-5);
        }

        &-description {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-medium) var(--done-font-size-20) /
            var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
          letter-spacing: var(--done-character-spacing--0-2);

          p:not(:first-child) {
            margin-top: 2.4rem;
          }
        }
      }
    }

    &-callback {
      right: 12rem;
      width: 22.8rem;

      &-fix {
        padding: 0 3rem;
        width: 22.8rem;
        height: 6.8rem;
        justify-content: space-evenly;
      }
    }

    &-footer {
      padding: 5rem;
      background: var(--done-color-eaeaf6) 0% 0% no-repeat padding-box;
      flex-direction: row;
      gap: 2.4rem;
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-20) / var(--done-line-spacing-20)
        var(--done-font-family-signal-mono);
      letter-spacing: var(--done-character-spacing--0-2);
      width: 100%;
      margin-bottom: 0;

      &-logos {
        display: block;
        flex-basis: 22.85%;
        margin-right: 10rem;
      }

      &-logo {
        margin-top: -10%;
        margin-bottom: 5rem;

        svg {
          height: auto;
        }
      }

      &-arrow {
        display: block;
        width: 29.25%;
        margin-bottom: 5rem;
      }

      &-social {
        height: 3.2rem;
        display: flex;
        justify-content: space-between;

        &-facebook,
        &-linkedin,
        &-youtube,
        &-glassdoor {
          width: 25%;

          svg {
            height: 2.8rem;
          }
        }
      }

      &-contact {
        flex-direction: column;
      }

      &-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &-link {
        flex-basis: 50%;
      }

      &-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 3rem;

        &-mobile {
          display: none;
        }
      }

      &-mentions {
        display: block;
        flex-basis: 50%;

        &-mobile {
          display: none;
        }
      }

      &-legal {
        height: calc(50% - 3.2rem);
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;

        &-link {
          flex-basis: 100%;

          svg {
            height: 1.7rem;
          }
        }
      }
    }

    &-scroll {
      display: none;

      &-desktop {
        display: flex;
      }
    }
  }

  /* Section video */

  .section-header {
    min-height: 100vh;
    max-height: 110rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    &-vision {
      flex-direction: column;
      background-image: url('../assets/img/animated-picto-green.gif');
      background-position: center;
      background-size: 84rem;
      position: relative;

      &-arrow {
        position: absolute;
        left: 5rem;
        bottom: 3rem;
      }
    }

    &-video {
      min-width: 100%;
      min-height: 100vh;
      height: initial;
    }
  }

  .section-ties {
    background: var(--done-color-ffffff) 0% 0% no-repeat padding-box;
    padding: 10rem;
    display: flex;
    justify-content: space-between;
  }
}

/* Cookies banner */

$animation-duration: 0.5s;

@keyframes showCookieBanner {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

.pointer {
  cursor: pointer;
}

.cookie-banner {
  z-index: 9999;
  position: fixed;
  background-color: var(--done-color-ffffff);
  bottom: 0;
  right: 0;
  left: 0;

  display: none;
  justify-content: center;

  &.show {
    animation: showCookieBanner $animation-duration ease-out;
  }

  &-cookies {
    max-width: 1440px;
    gap: var(--done-line-spacing-28);

    &-picto {
      flex-basis: 20%;
      display: flex;
      justify-content: center;
    }

    &-title {
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-32) / var(--done-line-spacing-55)
        var(--done-font-family-signal-mono);
      text-transform: uppercase;
    }

    &-text {
      flex-basis: 60%;

      p {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-28) var(--done-font-family-pp-neue-montreal);
      }
    }

    &-action {
      flex-basis: 20%;
      display: flex;
      flex-direction: column;

      .btn {
        width: auto;

        &-fast {
          height: 6.6rem;
          font-size: var(--done-font-size-16);
        }

        &-oneline {
          white-space: nowrap;
        }
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    @media only screen and (min-width: base.$large-devices) {
      padding: 5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

/* End cookies banner */

@media only screen and (min-width: base.$large-devices) and (orientation: portrait) {
  .section-header-video {
    min-height: 100vh;
  }
}

@media only screen and (min-width: base.$x-large-devices) {
  .done {
    &-section {
      padding: 6.8rem;

      &-accueil {
        padding-top: 16.8rem;
      }

      &-step {
        &-one {
          width: calc(20rem * 2.865);
        }

        &-image {
          height: 20rem;
        }

        &-two {
          width: calc(20rem * 3.797);
        }

        &-three {
          width: calc(20rem * 4.113);
        }
      }

      &-stories {
        &-arrow {
          display: revert;
          color: var(--done-color-ffffff);

          > svg {
            width: 11rem;
          }
        }
      }
    }

    &-nav {
      padding: 3.5rem 5rem;
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-20) / var(--done-line-spacing-20)
        var(--done-font-family-signal-mono);

      > a.logo {
        margin-right: 12rem;
      }

      &-scroll {
        &-down {
          padding: 0 3.5rem;
        }

        &-up {
          padding: 0 3.5rem;
        }
      }
    }
  }

  .done-section-stories .done-page {
    &-stories {
      grid-template-columns: 30rem 35.8rem;
    }
  }
}

@media only screen and (min-width: base.$xx-large-devices) {
  .done {
    &-section {
      padding: 10rem;

      &-accueil {
        padding-top: 20rem;
      }

      &-step {
        &-one {
          width: calc(22rem * 2.865);
        }

        &-image {
          height: 22rem;
        }

        &-two {
          width: calc(22rem * 3.797);
        }

        &-three {
          width: calc(22rem * 4.113);
        }
      }
    }
  }
}

// PROFIT IN GIVING Section

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

@media only screen and (max-width: base.$large-devices) {
  .done-section-timeline-detail-active-mobile {
    &::before {
      background: var(--done-color-00ff89) 0% 0% no-repeat padding-box;
      border: none;
    }
  }

  .done-campaign-fix {
    left: 0 !important;

    &-reduced {
      min-height: 8.6rem;
    }
  }
}

.center {
  text-align: center;
}

.padding-top-medium {
  padding-top: 7rem;
}

//campaign

#campaign {
  display: none;
}

.campaign-reduced {
  display: none;
}

.reduced-title {
  left: 0;
  width: auto;
  display: flex;
  text-align: center;
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
    var(--done-font-size-20) / var(--done-line-spacing-20)
    var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-2);
  cursor: pointer;
  gap: 2.4rem;

  &-hidden {
    display: none;
  }
}

.campaign-reduce {
  color: var(--done-color-000000);
  margin: 2rem 3rem auto auto;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  .reduce-btn-svg {
    width: 4.8rem;
    height: 4.8rem;
  }

  &-grey {
    color: var(--done-color-eaeaf6);
  }
}

.done-campaign {
  position: absolute;
  z-index: 99;
  right: 0;
  bottom: 0;
  width: 100%;

  &-fix {
    background: var(--done-color-ffffff) 0 0 no-repeat padding-box;
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
      var(--done-font-size-20) / var(--done-line-spacing-20)
      var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--0-2);
    color: var(--done-color-333834);
    z-index: 2000;
    bottom: 0;
    padding: var(--done-line-spacing-55);
    left: 0;

    &-img {
      padding: var(--done-line-spacing-30);
    }

    svg {
      color: var(--done-color-000000);
    }

    &-reduced {
      background: var(--done-color-eaeaf6) 0 0 no-repeat padding-box;

      .svg-wrapper {
        svg {
          height: 4rem;
          width: 7rem;
        }

        img {
          height: 5rem;
        }
      }

      span {
        margin: auto;
      }
    }

    &-grey {
      background: var(--done-color-333834) 0% 0% no-repeat padding-box;
      color: var(--done-color-eaeaf6);

      svg {
        color: var(--done-color-eaeaf6);
      }
    }

    &-banner {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      .campaign-help {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-14) /
          var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
        text-align: justify;
        display: none;
      }

      .campaign-date {
        text-transform: uppercase;
      }

      span {
        font: var(--done-font-style-normal) normal var(--done-font-weight-bold)
          var(--done-font-size-14) / var(--done-line-spacing-20)
          var(--done-font-family-pp-neue-montreal);
      }

      .vote-link {
        display: none;
      }

      .btn {
        padding: 0;
      }

      .btn-primary,
      .btn-secondary {
        margin-top: 2rem;
      }
    }

    svg,
    img {
      max-height: 8.2rem;
      max-width: 12.105rem;
    }

    &-abeilles {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: left;
      gap: var(--done-line-spacing-20);

      .campaign-finalist {
        text-transform: uppercase;
      }

      .campaign-help {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-medium) var(--done-font-size-16) /
          var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
      }

      .btn {
        padding: 0;
      }

      .vote-link {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: base.$large-devices) {
  #banner.done-campaign-fix {
    padding: 0 5rem 0 0;

    &-reduced {
      padding: 0 3rem;
    }
  }

  .campaign-reduce {
    margin: 0rem 1.6rem auto auto;

    &-grey {
      color: var(--done-color-eaeaf6);
    }
  }

  .done-campaign-fix {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--done-color-eaeaf6) 0 0 no-repeat padding-box;
    min-height: 6.8rem;
    color: var(--done-color-000000);

    &-img {
      padding: var(--done-line-spacing-30);
    }

    .svg-wrapper {
      display: flex;
      align-items: center;
      margin: 1.2rem;

      img {
        margin-left: 3rem;
      }
    }

    &-reduced {
      width: auto;
      right: 36rem;
      left: auto;

      .svg-wrapper {
        img {
          margin-left: 0rem;
        }
      }
    }

    &-grey {
      background: var(--done-color-333834) 0% 0% no-repeat padding-box;
      color: var(--done-color-eaeaf6);
    }

    &-black {
      background: var(--done-color-000000) 0% 0% no-repeat padding-box;
      color: var(--done-color-eaeaf6);
    }

    &-abeilles {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding-right: 2rem;

      &-brand {
        font-size: var(--done-font-size-28);
      }

      .campaign-finalist {
        flex-basis: 25%;
      }

      .campaign-help {
        flex-basis: 18%;
        text-transform: uppercase;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-16) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
      }

      .btn-primary,
      .btn-secondary {
        display: none;
      }

      .btn-fast {
        flex-basis: 10%;
      }

      .vote-link {
        display: block;
        text-align: center;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .btn {
          font-size: var(--done-font-size-28);
          text-decoration: underline;

          &:hover {
            cursor: pointer;
            text-decoration: none;
            color: var(--done-color-00ff89);
          }
        }
      }
    }

    &-banner {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding: 2rem 0;
      width: 100%;

      .campaign-date {
        flex-basis: 100%;
        display: flex;
      }

      span {
        font: var(--done-font-style-normal) normal var(--done-font-weight-bold)
          var(--done-font-size-16) / var(--done-line-spacing-20)
          var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-16);
      }

      .campaign-help {
        flex-basis: 100%;
        display: none;
        text-transform: uppercase;
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-16) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--0-16);
        text-align: left;
      }

      .btn-primary,
      .btn-secondary {
        display: flex;
        margin: 1rem 0;
        max-width: 30rem;
        margin-right: 2rem;
      }

      .btn-fast {
        flex-basis: 10%;
      }

      .vote-link {
        display: block;
        text-align: center;
        text-transform: uppercase;
        display: none;
        flex-direction: column;
        gap: 1rem;

        .btn {
          font: var(--done-font-style-normal) normal
            var(--done-font-weight-normal) var(--done-font-size-28) /
            var(--done-line-spacing-30) var(--done-font-family-signal-mono);
          text-decoration: underline;
          padding: 0 2rem;
          width: auto;

          &:hover {
            cursor: pointer;
            text-decoration: none;
            color: var(--done-color-00ff89);
          }
        }
      }
    }

    svg {
      height: 9rem;
      width: 19rem;
    }
  }
}
